const darkGreen = "rgb(38, 55, 51)";
const green = "rgb(41, 93, 78)";
import { View, Text, TouchableOpacity, TextInput, ScrollView, useWindowDimensions, Animated, Image } from "react-native";
import { useEffect, useRef, useState } from "react";
import { TypingAnimation } from 'react-native-typing-animation';
import { Configuration, OpenAIApi } from "openai";
import { Entypo } from '@expo/vector-icons';
import { useHover } from "@react-native-aria/interactions";


const specialite = {
    "naturopathe" : "Un naturopathe est un praticien de médecine naturelle qui met l'accent sur l'utilisation de méthodes de guérison naturelles pour favoriser la santé et le bien-être. La naturopathie repose sur le principe que le corps a une capacité innée d'auto-guérison et que la maladie résulte souvent d'un déséquilibre dans le corps. Les naturopathes utilisent des approches holistiques, telles que l'alimentation, les plantes médicinales, les compléments alimentaires, les techniques de relaxation et d'autres thérapies complémentaires pour traiter les maladies et soutenir la santé globale du patient. Ils adoptent une approche personnalisée en considérant les aspects physiques, émotionnels et environnementaux de chaque individu.",
    "psychiatre":"Un psychiatre est un médecin spécialisé en psychiatrie, une branche de la médecine qui se concentre sur l'évaluation, le diagnostic et le traitement des troubles mentaux. Contrairement aux psychologues, les psychiatres sont des médecins ayant suivi une formation médicale complète. Ils sont autorisés à prescrire des médicaments et à utiliser des approches thérapeutiques pour aider leurs patients. Les psychiatres travaillent souvent en collaboration avec des psychologues et d'autres professionnels de la santé mentale pour fournir des soins complets à leurs patients. Ils traitent une gamme de troubles, tels que la dépression, l'anxiété, la schizophrénie et les troubles bipolaires.",
    "psychologue" : "Un psychologue est un professionnel de la santé mentale qui étudie le comportement humain, les processus mentaux et les émotions. Les psychologues utilisent des méthodes scientifiques pour évaluer, diagnostiquer et traiter les troubles mentaux, les problèmes émotionnels et comportementaux. Ils peuvent travailler dans divers contextes tels que les hôpitaux, les cliniques, les écoles, ou en pratique privée. Les psychologues utilisent des approches thérapeutiques telles que la thérapie cognitivo-comportementale, la thérapie psychodynamique et la thérapie de soutien pour aider les individus à surmonter des difficultés psychologiques, à améliorer leur bien-être émotionnel et à développer des stratégies d'adaptation positives.",
    "ostéopathe" : "L'ostéopathie est une approche de médecine douce axée sur la manipulation manuelle des tissus du corps pour favoriser la guérison, la mobilité et l'équilibre. Les ostéopathes cherchent à identifier et à traiter les déséquilibres dans le système musculo-squelettique, en mettant l'accent sur la colonne vertébrale et les articulations. Ils considèrent le corps comme un tout intégré, croyant que les problèmes dans une partie peuvent avoir un impact sur d'autres régions. L'objectif est de restaurer la circulation sanguine et lymphatique, réduire les tensions et favoriser l'auto-guérison du corps.",
    "acuponcteur" : "L'acupuncture est une pratique médicale traditionnelle chinoise qui consiste à insérer de fines aiguilles dans des points spécifiques du corps pour rétablir l'équilibre énergétique. Selon la théorie de l'acupuncture, le corps est traversé par des canaux énergétiques appelés méridiens, et l'équilibre entre les forces opposées du yin et du yang est essentiel pour maintenir la santé. En stimulant les points d'acupuncture, l'acupuncteur cherche à rétablir le flux énergétique harmonieux, ce qui peut soulager la douleur, réduire le stress, et améliorer divers troubles et déséquilibres.",
    "réflexologue" : "La réflexologie est une méthode de médecine douce basée sur le principe que certaines zones réflexes des pieds, des mains ou des oreilles correspondent à des organes, des glandes et d'autres parties du corps. En appliquant une pression spécifique sur ces zones, les réflexologues cherchent à stimuler la circulation sanguine, éliminer les toxines et favoriser l'équilibre énergétique dans le corps. Cette pratique vise à améliorer la santé générale, à soulager le stress, à favoriser la détente et à soutenir les processus naturels de guérison du corps."
}






const Message = ({ el, i, mess }) => {

  const scale = useRef(new Animated.Value(0)).current;

  const animScale = () => {
    Animated.spring(scale, {
      bounciness: 14,
      toValue: 1,
      speed: 3,
      useNativeDriver: true,
    }).start()
  }


return (
  <Animated.View
    onLayout={() => {
      animScale();
    }}
    style={{ maxWidth: "80%", marginTop: 10, padding: el.type == 2 ? 0 : 10, borderRadius: 10, backgroundColor: el.type == 0 ? green : darkGreen, alignSelf: el.type == 0 ? "flex-end" : "flex-start", transform: [{ scale: scale }] }}>
    {el.type == 2 && i == mess.length - 1 &&
      <View style={{ width: 50, height: 40, borderRadius: 10, backgroundColor: darkGreen }}>
        <TypingAnimation
          dotColor={"white"}
          dotMargin={5}
          dotAmplitude={3}
          dotSpeed={0.15}
          dotRadius={4}
          dotX={12}
          dotY={8}
          style={{ position: "absolute", top: 10, left: 10 }}
        />
      </View>
    }
    <Text style={{ fontWeight: "500", color: "white" }}>{el.content}</Text>
  </Animated.View>
)
}


const SearchArea = ({ chat, setChat }) => {

  const [query, setQuery] = useState("");
  const { height } = useWindowDimensions()
  const [h, setH] = useState(height - 15 - 15 - 50);

  const [mess, setMess] = useState([]);
  const [openai, setOpenai] = useState();
  const [index, setIndex] = useState(3);
  const [loading, setLoading] = useState(false);





  useEffect(() => {

    const gatherResponse = async () => {
    setIndex((prevIndex) => prevIndex - 1); // Decrement 'index' by one
    console.log(index);
  
      setMess((old) => [...old, { type: 2, content: "" }])
      let nb_restant = { "role": "user",
      "content": "nombre de tentatives restantes: " + index,}
    
      chat.push(nb_restant)
      console.log(chat)
    const response = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: chat 
      });
      const resp = response.data.choices[0].message.content;   

      setMess((old) => [...old, { type: 1, content: resp }])
      setLoading(false);
    }

    if (mess.length != 0 && mess[mess.length - 1].type == 0) {
      gatherResponse();
    }
  }, [chat])


  useEffect(() => {
    const configuration = new Configuration({
      apiKey: "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"
    });
    setOpenai(new OpenAIApi(configuration))
  }, [])

  useEffect(() => {

    if (mess.length != 0 && mess[mess.length - 1].type == 0) {
      setLoading(true)
      setChat((old) => [...old, {
        "role": "user",
        "content": mess[mess.length - 1].content,
      }])
    }

  }, [mess.length])

  const textOpacity = useRef(new Animated.Value(1)).current;
  const hideText = () => {
    Animated.timing(textOpacity, {
      duration: 800,
      toValue: 0,
      useNativeDriver: true,
    }).start(() => {
      setMess((old) => [...old, { type: 0, content: query }])
    })
  }

  const messScrollRef = useRef(null)

  return (
    <View style={{ width: "100vw", alignItems: "center", minHeight: h, paddingTop: textOpacity._value == 0 ? 0 : 0.25 * height }}>
      {textOpacity._value != 0 && <Animated.Text style={{ fontSize: 45, textAlign: "center", fontWeight: "bold", opacity: textOpacity, color: darkGreen }}>{`Medalink AI v1`}</Animated.Text>}
      {textOpacity._value == 0 && <ScrollView onContentSizeChange={() => messScrollRef.current.scrollToEnd({ animated: true })} ref={messScrollRef} style={{
        minWidth: 400,
        maxWidth: 600,
        width: "50vw",
        marginTop: 50,
        paddingHorizontal: 15,
        paddingBottom: 10,
        height: textOpacity._value == 0 ? "calc(100vh - 80px - 77px - 100px)" : null // top bar (80px) | search bar (57px + 20px de margin)
      }}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        {
          mess.map((el, i) => {
            return <Message key={i} el={el} i={i} mess={mess} />
          })
        }
      </ScrollView>}
      <View style={{ flexDirection: "row", marginTop: 50 }}>
        <Entypo name="magnifying-glass" size={26} color={green} style={{ position: "absolute", top: 14, left: 14 }} />
        <TextInput
          editable={!loading}
          placeholder="Decrivez vos symptomes.."
          value={query}
          onChange={(e) => setQuery(e.nativeEvent.text)}
          placeholderTextColor={darkGreen}
          style={{
            marginBottom: 20,
            outline: "none",
            padding: 20,
            paddingLeft: 20 + 30,
            borderRadius: 20,
            minWidth: 400,
            maxWidth: 600,
            width: "50vw",
            // shadowColor: "#000",
            shadowColor: darkGreen,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            shadowOpacity: 0.4,
            shadowRadius: 7,
            fontWeight: "500"
          }}
          onSubmitEditing={() => {
            if (textOpacity._value == 1) {
              setQuery("");
              hideText();
            } else {
              setQuery("");
              setMess((old) => [...old, { type: 0, content: query }])
            }
            // setH((old) => old+500)
          }}
        />
      </View>
    </View>
  )
}


const App = () => {

  const [chat, setChat] = useState([{
    "role": "system", "content":
    `
    Bonjour chat GPT. 
Tu es desormais un spécialiste dans les medecines douces. 
Tu sais maintenant établir quel specialiste de medecine douce il convient le mieux de consulter en fonction de symptomes.
Tu vas donc m'aider en me posant des questions pour essayer de me trouver le spécialiste de medecine douce qui serait le plus apte à m'aider en fonction de mes symptomes.

Ton but n'est dans aucun cas de faire un diagnostic, mais seulement de me poser des questions.

pose moi les questions prompt par prompt. Dans chacun des prompts tu ne fais apparaitre QUE les questions et aucune autre informations


les pratiques que tu connais sont:

-ostéopathe
-acuponcteur
-reflexologue
-psychologue
-psychiatre
-naturopathe




je veux que ton ouput final soit SEULEMENT une phrase: 'Je te conseil de consulter un x' avec x le nom de la specialité que tu auras trouvée


Si tu as compris ta tâche, REPOND SEULEMENT: VALIDE
  `}])


  const [showSignModal, setShowSignModal] = useState(false);
  const [isInOrUp, setIsInOrUp] = useState(false);

  return (
    <ScrollView style={{ height: "100vh" }}>
      {
        showSignModal && <AuthModal setShowModal={setShowSignModal} inOrUp={isInOrUp} />
      }
     

    
          <SearchArea chat={chat} setChat={setChat} />
        

    </ScrollView>
  )
}

export default App;